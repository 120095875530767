import {Dictionary, Route} from 'vue-router/types/router';
import Router from 'vue-router';
import {NullString} from '@/types/NullTypes';

export default class RouteHelper {

    static router: Router;
    static route: Route;

    public static setRouter(value: Router) {
        this.router = value;
    }

    public static setRoute(value: Route) {
        this.route = value;
    }

    public static toNumberUndefined(value: any) {
        if (value === undefined) {
            return undefined;
        }
        return Number(value);
    }

    public static toArrayString(value: any[] | string) {
        if (!value) {
            return [];
        }
        const out: number[] = [];
        if (typeof value === 'object') {

            value.forEach(((val) => {
                out.push(Number(val));
            }));
        } else {
            out.push(Number(value));
        }
        return out;
    }

    public static getQuery(route: Route, obj: any) {
        if (route.query.query && route.query.query !== '') {
            obj.query = RouteHelper.toString(route.query.query);
        }
    }

    public static getAct(route: Route, obj: any) {
        if (route.query.act && route.query.act !== '') {
            obj.act = RouteHelper.toString(route.query.act);
        }
    }

    public static getOffset(route: Route, obj: any) {
        if (route.query.offset && route.query.offset !== '') {
            obj.offset = RouteHelper.toString(route.query.offset);
        }
    }

    public static getLimit(route: Route, obj: any) {
        if (route.query.limit && route.query.limit !== '') {
            obj.limit = RouteHelper.toString(route.query.limit);
        }
    }

    public static getSort(route: Route, obj: any) {
        if (route.query.sort && route.query.sort !== '') {
            obj.sort = String(route.query.sort);
        }
    }

    public static getOrder(route: Route, obj: any) {
        if (route.query.order && route.query.order !== '') {
            obj.order = String(route.query.order);
        }
    }

    public static getStandardParams(route: Route, obj: any) {
        this.getAct(route, obj);
        this.getQuery(route, obj);

        this.getOrder(route, obj);
        this.getSort(route, obj);
        this.getLimit(route, obj);
        this.getOffset(route, obj);
    }

    public static getPushStandardParams(query: Dictionary<string | string[]>, obj: any) {
        if (obj.act) {
            query.act = obj.act;
        }
        if (obj.query) {
            query.query = obj.query;
        }
        if (obj.order !== '') {
            query.order = obj.order;
        }
        if (obj.sort !== '') {
            query.sort = obj.sort;
        }
        if (obj.offset !== '') {
            query.offset = obj.offset;
        }
        if (obj.limit !== '') {
            query.limit = obj.limit;
        }
        if (obj.createdAtFrom) {
            query.createdAtFrom = obj.createdAtFrom;
        }
        if (obj.createdAtTo) {
            query.createdAtTo = obj.createdAtTo;
        }
    }

    public static toNumberNull(value: any) {
        if (value === undefined) {
            return null;
        }
        return Number(value);
    }

    public static toStringNull(value: any): NullString {
        if (!value) {
            return null;
        }
        return String(value);
    }

    public static toString(value: any) {
        if (value === undefined) {
            return '';
        }
        return String(value);
    }

    public static queryAssign(route: Route, obj: any) {

        Object.entries(route.query).forEach(([key, value]) => {
            if (typeof value === 'string' && (typeof obj[key] === 'string' || typeof obj[key] === 'number')) {
                if (typeof obj[key] === 'string') {
                    obj[key] = this.route.query[key];
                }
                if (typeof obj[key] === 'number') {
                    obj[key] = Number(this.route.query[key]);
                }
            }

            if (obj[key] != null && typeof obj[key] === 'object') {
                obj[key] = [];
                if (typeof value === 'object') {
                    Object.entries(value).forEach(([k, v]) => {
                        obj[key].push(Number(v));
                    });
                } else {
                    obj[key].push(Number(value));
                }
            }


        });

    }

    public static queryToObject(query: Dictionary<string | (string | null)[]>, params: any) {
        Object.entries(params).forEach(([key, value]) => {
            if (typeof value === 'string' && typeof query[key] === 'string') {
                params[key] = query[key] as string;
            }
            if (typeof value === 'object' && typeof query[key] === 'object') {
                params[key] = [];
                Object.entries(query[key]).forEach(([v]) => {
                    params[key].push(Number(v) as never);
                });
            }
        });

    }
}
