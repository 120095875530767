import Vue from 'vue';
import moment from 'moment';

Vue.filter('timestampFormat', (value: number, format: string) => {


    if (value === null || value === undefined || format === undefined || Number(value) === 0) {
        return '';
    }
    if (format === 'from') {
        return moment(value).fromNow();
    }

    return moment(Number(value) * 1000).format(format);

});




