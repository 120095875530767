
import {Component, Vue} from 'vue-property-decorator';
import Http from '@/Http';
import AppStore from '@/components/AppStore';

@Component({})
export default class AppTopBar extends Vue {

  clientName = AppStore.clientName;
  containerWidth = AppStore.containerWidth;

  created() {
    AppStore.onContainerWidth(v => this.containerWidth = v);
  }

  loginOut() {

    AppStore.showLoader();
    Http.post('/login/out', {
      hash: this.$route.query.hash
    }).then((value: any) => {
      AppStore.snackbarOpenSuccess('Вы успешно вышли.');
      document.location.href = '/login/in';
    }).finally(() => {
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });

  }

}
