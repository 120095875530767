import Vue from 'vue';

Vue.filter('currency', (value: number) => {

    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'decimal',
        currency: 'RUS',
        minimumFractionDigits: 0
    });

    return formatter.format(value);

});
