
import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import Http from '@/Http';

@Component({})
export default class ExportExcelDialog extends Vue {

  @Model('setModel')
  model!: boolean;

  @Prop({default: 'transports'})
  viewMode!: 'transports' | 'dcs' | 'fines';

  view = false;
  loading = true;
  url = '';

  @Watch('model')
  modelWatch() {
    if (this.model) {
      this.view = true;
      this.load();
    }
  }

  getUrl(): string {
    if (this.viewMode === 'transports') {
      return '/transports/export';
    } else if (this.viewMode === 'dcs') {
      return '/dcs/export';
    } else {
      return '/fines/export';
    }
  }

  load() {
    this.url = '';
    this.view = true;
    this.loading = true;
    Http.post(this.getUrl(),).then((response: any) => {
      this.url = response.url;
    }).finally(() => {
      this.loading = false;
    }).catch((HttpError) => {
      this.close();
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

  beforeDestroy() {
    this.close();
  }

  close() {
    this.view = false;
    this.$emit('setModel', false);
  }
}
