
import {Component, Vue} from 'vue-property-decorator';
import Http from '@/Http';
import AppStore from '@/components/AppStore';

@Component({})
export default class AppSnackbar extends Vue {

  snackbar = false;
  text = 'text';
  btnColor = 'pink';

  created() {
    Http.onSystemError((error) => {
      this.btnColor = 'red darken-2';
      this.text = error;
      this.snackbar = true;
    });

    AppStore.onSnackbarOpenSuccess((value => {
      this.btnColor = 'green';
      this.text = value;
      this.snackbar = true;
    }));
  }
}
