import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './filters/currency';
import './filters/timestampFormat';
import './filters/dateFormat';
import './filters/odometerFormat';

// @ts-ignore
import VueMoment from 'vue-moment';

// @ts-ignore
import Fragment from 'vue-fragment';

// @ts-ignore
import {VueMaskPlugin} from 'v-mask';
import moment from 'moment';

Vue.use(VueMoment, {moment});

//Vue.use(VueMaskPlugin);
Vue.use(Fragment.Plugin);

Vue.use(VueMaskPlugin, {
    placeholders: {
        '#': /\d/,
        G: /[0-9а-яА-Я]/i,
        Y: /[0-9a-zA-Z]/i,
    }
});

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');


