export default class TransportFilter {

    query = '';

    statusProgress = 0;
    passEnded = 0;
    passMissing = 0;

    rnisNoReg = 0;
    rnisNoTransmitted = 0;

    order = 'passDayEndDate';
    sort = 'ASC';
}
