import Vue from 'vue';
import {NullNumber} from '@/types/NullTypes';

Vue.filter('odometerFormat', (value: number, dcOdometer: any) => {
    if (!value) {
        return '';
    }
    return new Intl.NumberFormat().format(value);
});




