
import {Component, Vue} from 'vue-property-decorator';

import Http from '@/Http';
import RouteHelper from '@/helpers/RouteHelper';
import AppStore from '@/components/AppStore';
import AppSnackbar from '@/components/UI/AppSnackbar.vue';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import ExportExcelDialog from '@/components/ExportExcelDialog.vue';

@Component({
  components: {ExportExcelDialog, AppTopBar, AppSnackbar},
})

export default class App extends Vue {

  processBarView = false;
  loading = true;

  created() {

    if (document.location.pathname.indexOf('/login') !== -1) {
      this.loading = false;
    } else {

      AppStore.showLoader();
      this.loading = true;
      Http.post('/client', {})
          .then((value: any) => {
            AppStore.clientName = value.name;
            AppStore.regNumbers = value.regNumbers;
            AppStore.isFinesEnabled = value.isFinesEnabled;
            AppStore.isRnisEmulationEnabled = value.isRnisEmulationEnabled;
            AppStore.manager = value.manager;
            AppStore.assistant = value.assistant;
          })
          .finally(() => {
            AppStore.hideLoader();
            this.loading = false;
          }).catch((HttpError) => {
        Http.systemError(HttpError.error);
      });

    }

    AppStore.off();
    AppStore.onShowLoader(value => this.processBarView = value);

    const dm = localStorage.getItem('displayMode');
    if (dm) {
      AppStore.setDisplayMode(dm);
    } else {
      if (this.$vuetify.breakpoint.xs) {
        AppStore.setDisplayMode('block');
      } else {
        AppStore.setDisplayMode('table');
      }
    }

    Http.setRouter(this.$router);
    RouteHelper.setRouter(this.$router);
    RouteHelper.setRoute(this.$route);
  }

}


