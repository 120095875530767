import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#301c5e',
                info: '#5e3a91',
                secondary: colors.grey.darken1,
                accent: '#9776ea',
                error: '#ff4747',
            }
        }
    }


});
