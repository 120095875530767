import Vue from 'vue';
import moment from 'moment';

Vue.filter('dateFormat', (value: any, format: string) => {
    if (value === null || value === 0 || value === undefined || format === undefined) {
        return '';
    }

    if (value.length < 10) {
        return '';
    }

    if (moment.unix(value).isValid()) {
        return moment.unix(value).format(format);
    } else {
        return moment(value).format(format);
    }

});




