export default class UserManager {

    avatar='';
    label = '';
    email = '';
    phone = '';


    constructor(init?: Partial<UserManager>) {
        Object.assign(this, init);
    }

}

